import axios from 'axios'

const appSettings = {}

export async function load() {
  const appSettingsJson = await axios.get('/appsettings.json')
    .then(response => response.data)
  if (typeof appSettingsJson === 'object' && appSettingsJson !== null) {
    for (const [key, value] of Object.entries(appSettingsJson)) {
      appSettings[key] = value
    }
  } else {
    console.error('Ignoring invalid appsettings.json', appSettingsJson)
  }
}

export default appSettings
